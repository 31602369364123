import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  current: {}
}

const partialsSlice = createSlice({
  name: 'partials',
  initialState,
  reducers: {
    setPartialsList: (state, { payload }) => ({ ...state, list: [...payload.partials] }),
    setCurrentPartial: (state, { payload }) => ({ ...state, current: { ...payload.partial } }),
    resetPartialsState: () => ({ list: [], current: { } })
  }
});

export const { setPartialsList, setCurrentPartial, resetPartialsState } = partialsSlice.actions;
export default partialsSlice.reducer;
