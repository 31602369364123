import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCurrentProject } from "../../../../../actions/projectsActions";
import { resetProjectsState } from "../../../../../slices/projectsSlice";
import PageTitle from "./components/PageTitle";
import ImportExcelButton from "./components/ImportExcelButton";
// import { Button } from "@mui/material";
import { getInvestmentsList } from "../../../../../actions/investmentsActions";
import ProjectInvestors from "./components/ProjectInvestors/ProjectInvestors";
function DistribitionExcelByProjectPage() {
  const params = useParams();

  const dispatch = useDispatch();
  const current = useSelector((state) => state.projects.current);

  useEffect(() => {
    dispatch(getCurrentProject({ id: params.id }));
    dispatch(
      getInvestmentsList({ query: { projectRef: params.id, results: 999999 } })
    );
    return () => dispatch(resetProjectsState());
  }, []);

  const [data, setData] = useState([]);

  const handleFile = (newData) => {
    setData(newData);
  };

  // const createRequests = () => {
  //   console.log(data);
  // };

  return (
    <div>
      <PageTitle name={current.name} id={params.id} />
      <ImportExcelButton uploadHandler={handleFile} />
      <ProjectInvestors fileData={data} />
      {/* <Button onClick={createRequests}>Create</Button> */}
    </div>
  );
}

export default DistribitionExcelByProjectPage;
