import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Check, Delete } from '@mui/icons-material';
import { TableRow, TableCell, IconButton, TextField } from '@mui/material';
import { useState } from 'react';
import CurrencyField from '../inputs/CurrencyField';
import PercentageField from '../inputs/PercentageField';

import { deleteCurrentLoan, updateCurrentLoan } from '../../actions/loansActions';

const ProjectDetailsLoanItem = props => {
  const dispatch = useDispatch();

  const params = useParams();

  const [data, setData] = useState({
    amount: props.amount ?? 0,
    deadline: props.deadline ?? '',
    rate: props.rate ?? '',
    amortization: props.amortization ?? 0,
    gracePeriod: props.gracePeriod ?? '',
    depositDate: new Date(props.depositDate).toISOString().slice(0, 10),
  });

  const handleChange = e => setData(prev => ({ ...prev, [e.target.name]: e.target.rawValue ?? e.target.value }));

  const handleSave = e => {
    e?.preventDefault();
    dispatch(updateCurrentLoan({ id: props._id, loan: data }));
  }

  const handleDelete = e => {
    e?.preventDefault();
    dispatch(deleteCurrentLoan({ id: props._id, query: { projectRef: params.id } }));
  }

  return (
    <TableRow>
      <TableCell><CurrencyField label="Monto" name="amount" value={data.amount} onChange={handleChange} /></TableCell>
      <TableCell><TextField label="Plazo" name="deadline" value={data.deadline} onChange={handleChange} /></TableCell>
      <TableCell><PercentageField label="Tasa" name="rate" value={data.rate} onChange={handleChange} /></TableCell>
      <TableCell><CurrencyField label="Amortización" name="amortization" value={data.amortization} onChange={handleChange} /></TableCell>
      <TableCell><TextField label="Periodo de gracia" name="gracePeriod" value={data.gracePeriod} onChange={handleChange} /></TableCell>
      <TableCell><TextField type="date" label="Fecha de depósito" name="depositDate" value={data.depositDate} onChange={handleChange} /></TableCell>
      <TableCell><IconButton onClick={handleSave}><Check /></IconButton></TableCell>
      <TableCell><IconButton onClick={handleDelete}><Delete /></IconButton></TableCell>
    </TableRow>
  );
}

export default ProjectDetailsLoanItem;
