import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import store from "./store/store";

import { setToken } from "./slices/authSlice";

// Sentry.init({
//   dsn: "https://a139ef2a88ec4532b77f4a7c5d885898@o4504481251196928.ingest.sentry.io/4504759949852672",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

const token = window.localStorage.getItem("token");
const expires = window.localStorage.getItem("expires");

if (token && new Date() < Number(expires)) {
  store.dispatch(setToken({ token }));
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
