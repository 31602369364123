import { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import { currencyFormatter } from "../../../../utils/formatUtils";
import ProjectDetailsInvestorAdd from "./ProjectDetailsInvestorAdd";

const styles = {
  container: {
    display: "block",
  },
  content: {
    paddingY: 0,
  },
  actions: {
    paddingRight: "1rem",
  },
  title: {
    my: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fieldWrapper: {
    px: "1rem",
  },
};

const ProjectDetailsInvetsments = (props) => {
  const investments = useSelector((state) => state.investments.list);
  const [openAdd, setOpenAdd] = useState(false);

  const handleClose = (e) => {
    e.preventDefault();
    props.onClose();
  };

  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  return (
    <>
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle>
          <Box sx={styles.title}>
            <Typography variant="h5">Inversionistas</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <Divider />
        </DialogTitle>
        <DialogContent sx={styles.content}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Inversionista</TableCell>
                <TableCell>Contacto</TableCell>
                <TableCell>Capital</TableCell>
                <TableCell>Participación</TableCell>
                {/* <TableCell>Crédito</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {investments.map((investment, index) => (
                <TableRow key={investment._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <Link
                      to={`/dashboard/asociados/${investment.associateRef?._id}`}
                    >
                      {investment?.investor ||
                        investment.associateRef?.investor}
                    </Link>
                  </TableCell>
                  <TableCell>{investment.associateRef?.name}</TableCell>
                  <TableCell>
                    {currencyFormatter.format(investment.capital)}
                  </TableCell>
                  <TableCell>{investment.participation}%</TableCell>
                  {/* <TableCell>{investment.credit?.credit ?? 0}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions sx={styles.actions}>
          <Button onClick={handleOpenAdd}>Añadir</Button>
        </DialogActions>
      </Dialog>
      <ProjectDetailsInvestorAdd open={openAdd} onClose={handleCloseAdd} />
    </>
  );
};

export default ProjectDetailsInvetsments;
