import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Close, Image } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, Button, Typography, IconButton, Divider, Grid } from '@mui/material';

import { uploadCurrentProjectImage } from '../../../../actions/projectsActions';
import ProjectDetailsImagesItem from './ProjectDetailsImagesItem';

const styles = {
  container: {
    display: 'block',
  },
  content: {
    paddingY: 0,
    width: {
      md: '60vw'
    }
  },
  actions: {
    paddingRight: '1rem'
  },
  title: {
    my: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fieldWrapper: {
    px: '1rem'
  }
}

const ProjectDetailsImagesModal = props => {
  const dispatch = useDispatch();
  const params = useParams();
  const [file, setFile] = useState(null);

  const handleFileChange = e => setFile(e.target.files[0]);

  const handleClose = e => {
    e?.preventDefault();
    props.onClose();
  }

  const handleSubmit = e => {
    e?.preventDefault();
    dispatch(uploadCurrentProjectImage({ id: params.id, file }));
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>
       <Box sx={styles.title}>
          <Typography variant="h5">Imágenes</Typography>
          <IconButton onClick={handleClose}><Close /></IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ px: '1rem' }}>
            <Typography variant="h6">Nueva imagen</Typography>
            <Box sx={{ pt: '3rem', textAlign: 'center' }}>
              <Button variant="text" component="label">
                <input hidden type="file" onChange={handleFileChange} />
                <Image />
              </Button>
              <Typography variant="caption">{file ? file.name : 'Seleccionar archivo'}</Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Button disabled={!file} onClick={handleSubmit}>Guardar</Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ px: '1rem' }}>
            <Typography variant="h6">Imágenes del proyecto</Typography>
            {props.images?.map(image => <ProjectDetailsImagesItem key={image._id} {...image} />)}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ProjectDetailsImagesModal;
