// import { useState } from 'react';
import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { currencyFormatter } from "../../../../../utils/formatUtils";
// import InvestorProjectDetailsReports from './InvestorProjectDetailsReports';

const InvestorProjectDetailsInfo = (props) => {
  // const [reports, setReports] = useState(false);

  // const handleOpenReports = () => setReports(true);
  // const handleCloseReports = () => setReports(false);

  return (
    <>
      <Card>
        <CardHeader title="Proyecto" />
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h4">{props.projectRef?.name}</Typography>
              <Typography variant="h6">
                Inversión total en el proyecto:{" "}
                {currencyFormatter.format(props.projectRef?.investment)}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: "1rem" }}>
              {/* <Button fullWidth onClick={handleOpenReports}>Reportes</Button> */}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* <InvestorProjectDetailsReports open={reports} onClose={handleCloseReports} /> */}
    </>
  );
};

export default InvestorProjectDetailsInfo;
