import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Divider, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { deleteCurrentProjectImage } from '../../../../actions/projectsActions';

const { REACT_APP_HOST_URL } = process.env;

const ProjectDetailsImagesItem = ({ filter, ...props }) => {
  const dispatch = useDispatch();
  const params = useParams();
  
  const handleDelete = e => {
    e?.preventDefault();
    dispatch(deleteCurrentProjectImage({ id: params.id, imageId: props._id }));
  }

  return (
    <>
      <Box sx={{ py: '1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <a href={`${REACT_APP_HOST_URL}/images/projects/${props.path}`} target="_blank" rel="noreferrer">{props.path}</a>
        </Box>
        <IconButton onClick={handleDelete}><Delete /></IconButton>
      </Box>  
      <Divider />
    </>
  );
}

export default ProjectDetailsImagesItem;
