import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginAdmin } from '../api/authApi';
import { clearToken, setToken } from '../slices/authSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';

export const login = createAsyncThunk('auth/login', ({ user, password, callback }, { dispatch }) => {
  dispatch(startLoading());

  loginAdmin(user, password)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => {
    const expires = new Date();
    expires.setHours(expires.getHours() + 12);
    dispatch(setToken({ token: data.token }));
    window.localStorage.setItem('token', data.token);
    window.localStorage.setItem('expires', Number(expires));
  })
  .then(() => callback())
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const logout = createAsyncThunk('auth/logout', ({ callback }, { dispatch }) => {
  dispatch(startLoading());

  dispatch(clearToken());
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('expires');
  dispatch(endLoading());
  callback();
});
