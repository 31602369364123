import { Link } from 'react-router-dom';
import { Box, Button, Card, CardContent, CardHeader, Grid } from '@mui/material';
import { useSelector } from 'react-redux';

const styles = {
  inputWrapper: {
    margin: '0.5rem 1rem'
  },
  submit: {
    textAlign: 'center'
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  }
}

const InvestorDetailsProjects = () => {
  const investments = useSelector(state => state.investments.list);

  return (
    <Card>
      <CardHeader title="Proyectos/Inversiones" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            {investments.map(investment => 
              <Box key={investment._id} sx={styles.inputWrapper}>
                <Button component={Link} to={`inversion/${investment._id}`} variant="text">{investment.projectRef?.name} ({investment?.investor})</Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default InvestorDetailsProjects;
