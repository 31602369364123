import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, Button, DialogActions, Typography, IconButton, Divider, Grid, TextField } from '@mui/material';
import CurrencyField from '../../../../components/inputs/CurrencyField';

import { createNewPartial, getPartialsList } from '../../../../actions/partialsActions';

const defaultState = {
  concept: '',
  total: '',
  paid: ''
};

const styles = {
  container: {
    display: 'block',
  },
  content: {
    paddingY: 0,
  },
  actions: {
    paddingRight: '1rem'
  },
  title: {
    my: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fieldWrapper: {
    px: '1rem'
  }
}

const ProjectDetailsPartialCreate = props => {
  const [partial, setPartial] = useState({ ...defaultState });

  const dispatch = useDispatch();
  const params = useParams();

  const handleChange = e => setPartial({ ...partial, [e.target.name]: e.target.rawValue ?? e.target.value });

  const handleClose = e => {
    e?.preventDefault();
    setPartial({ ...defaultState });
    props.onClose();
  }

  const handleSubmit = e => {
    dispatch(createNewPartial({ partial: { ...partial, projectRef: params.id }, callback: () => {
      handleClose();
      dispatch(getPartialsList({ query: { projectRef: params.id } }));
    } }));
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>
       <Box sx={styles.title}>
          <Typography variant="h5">Crear aportación</Typography>
          <IconButton onClick={handleClose}><Close /></IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container>
          <TextField label="Concepto" name="concept" value={partial.concept} onChange={handleChange} />
          <CurrencyField label="Total" name="total" value={partial.total} onChange={handleChange} />
          <CurrencyField label="Pagado" name="paid" value={partial.paid} onChange={handleChange} />
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button onClick={handleSubmit}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProjectDetailsPartialCreate;
