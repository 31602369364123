import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Switch,
} from "@mui/material";
import CurrencyField from "../../../../components/inputs/CurrencyField";
import PercentageField from "../../../../components/inputs/PercentageField";
import ProjectDetailsDescriptionDialog from "./ProjectDetailsDescriptionDialog";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
const styles = {
  inputWrapper: {
    margin: "0rem 1rem",
  },
  submit: {
    textAlign: "center",
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
};

const ProjectDetailsInfo = (props) => {
  const navigate = useNavigate();
  const params = useParams();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Card>
        <CardHeader title="Datos de la inversión" />
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box sx={styles.inputWrapper}>
                <CurrencyField
                  label="Inversión en el Proyecto"
                  name="investment"
                  value={props.investment}
                  onChange={props.onChange}
                />
              </Box>

              <Box sx={{ margin: "0.5rem 1rem" }}>
                USD
                <Switch
                  checked={props.isInvestmentUSD}
                  name="isInvestmentUSD"
                  onChange={props.onChange}
                />
              </Box>
              <Box sx={styles.inputWrapper}>
                <PercentageField
                  label="Porcentaje de Participación"
                  name="percentageIde"
                  value={props.percentageIde}
                  onChange={props.onChange}
                />
              </Box>
              {/* <Box sx={styles.inputWrapper}>
              <TextField
                type="number"
                label="Número de aportaciones"
                name="contributions"
                value={props.contributions}
                onChange={props.onChange}
              />
            </Box> */}
              {/* <Box sx={styles.inputWrapper}>
              <Button fullWidth onClick={() => props.onOpen("loans")}>
                Créditos IDE
              </Button>
            </Box> */}
              {/* <Box sx={styles.inputWrapper}>
              <Button fullWidth onClick={() => props.onOpen("partials")}>
                Número de Aportaciones
              </Button>
            </Box> */}
              <Box sx={styles.inputWrapper}>
                <PercentageField
                  type="number"
                  label="TIR a inversionistas"
                  name="provisions"
                  value={props.provisions}
                  onChange={props.onChange}
                />
              </Box>
              <Box sx={styles.inputWrapper}>
                <PercentageField
                  label="Rendimiento anual esperado"
                  name="performance"
                  value={props.performance}
                  onChange={props.onChange}
                />
              </Box>
              <Box sx={{ margin: "0.5rem 1rem" }}>
                Montos en Dolares
                <Switch
                  checked={props.isUSD}
                  name="isUSD"
                  onChange={props.onChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={styles.actions}>
              <Box sx={styles.inputWrapper}>
                <Button fullWidth onClick={handleOpen}>
                  Descripción del proyecto
                </Button>
              </Box>
              {/* <Box sx={styles.inputWrapper}>
                <Button fullWidth onClick={() => props.onOpen("interests")}>
                Pago de intereses
              </Button>
              </Box> */}
              {/* <Box sx={styles.inputWrapper}>
                <Button fullWidth onClick={() => props.onOpen("distributions")}>
                Pago de distribuciones
              </Button>
              </Box> */}
              <Box sx={styles.inputWrapper}>
                <Button fullWidth onClick={() => props.onOpen("investments")}>
                  Lista de inversionistas
                </Button>
              </Box>
              <Box sx={styles.inputWrapper}>
                <Button
                  color="success"
                  fullWidth
                  onClick={() => {
                    navigate(
                      "/dashboard/proyectos/" +
                        params.id +
                        "/importar-distribuciones"
                    );
                  }}
                  startIcon={<TextSnippetIcon />}
                >
                  Carga masiva Distribución
                </Button>
              </Box>
              <Box sx={styles.inputWrapper}>
                <Button
                  color="success"
                  fullWidth
                  onClick={() => {
                    navigate(
                      "/dashboard/proyectos/" +
                        params.id +
                        "/agregar-ultimo-reporte"
                    );
                  }}
                  startIcon={<PictureAsPdfIcon />}
                >
                  Carga masiva Reportes
                </Button>
              </Box>
              <Box sx={styles.inputWrapper}>
                <Button
                  fullWidth
                  color="secondary"
                  onClick={() => props.onOpen("reports")}
                >
                  Reportes informativos
                </Button>
              </Box>
              <Box sx={styles.inputWrapper}>
                <Button
                  fullWidth
                  color="secondary"
                  onClick={() => props.onOpen("finance")}
                >
                  Información financiera
                </Button>
              </Box>
              <Box sx={styles.inputWrapper}>
                {/* <Button fullWidth onClick={() => props.onOpen("fees")}>
                Fees
              </Button> */}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={styles.submit}>
                <Button onClick={props.onSubmit}>Guardar</Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ProjectDetailsDescriptionDialog
        open={open}
        onClose={handleClose}
        description={props.description}
        image={props.descriptionImage}
      />
    </>
  );
};

export default ProjectDetailsInfo;
