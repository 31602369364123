import { forwardRef, useImperativeHandle } from "react";
import { InputAdornment, TextField } from "@mui/material";
import Cleave from "cleave.js/react";

const CurrencyInput = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    focus: () => {},
  }));

  return (
    <Cleave options={{ numeral: true, rawValueTrimPrefix: true }} {...props} />
  );
});

const CurrencyField = (props) => (
  <TextField
    disabled={props.disabled}
    InputProps={{
      inputComponent: CurrencyInput,
      startAdornment: <InputAdornment position="start">$</InputAdornment>,
    }}
    {...props}
  />
);

export default CurrencyField;
