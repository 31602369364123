import { createAsyncThunk } from '@reduxjs/toolkit';
import { createNotification, saveToken } from '../api/notificationsApi';
import { showAlert } from '../slices/alertsSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';


export const createNewNotification = createAsyncThunk('notifications/createNewNotification', ({ notification, callback }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createNotification(token, notification)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.users))
  .then(() => dispatch(showAlert({ message: 'Notificación enviada', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const updateToken = createAsyncThunk('notifications/updateToken', ({ id, notification }, { getState, dispatch }) => {
  const { token } = getState().auth;
  
  saveToken(token, id, notification)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .catch(err => dispatch(handleError(err)))
});
