import { useSelector } from "react-redux";
import CopyAll from "@mui/icons-material/CopyAll";
// import Delete from "@mui/icons-material/Delete";

import {
  TableRow,
  TableCell,
  // IconButton,
  Box,
  Typography,
  Tooltip,
  // Button,
  Snackbar,
} from "@mui/material";
import { Link } from "react-router-dom";
// import { deleteCurrentInvestment } from "../../../actions/investmentsActions";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React, { useState } from "react";
const AssociateListItem = (props) => {
  // const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const { list } = useSelector((state) => state.projectAssociates);

  // const handleDelete = (e) => {
  //   e?.preventDefault();
  //   dispatch(
  //     deleteCurrentInvestment({
  //       id: props._id,
  //       callback: () => props.onDelete(),
  //     })
  //   );
  // };

  const { projects } = props;

  const handleCopy = () => {
    navigator.clipboard.writeText(props._id);
    setOpen(true);
  };

  return (
    <TableRow>
      <TableCell>
        <Typography component={"p"} variant="caption" textAlign={"center"}>
          {(props.index ?? 0) + 1}
        </Typography>
      </TableCell>
      <TableCell>
        <Link to={props?._id ?? "/"}>
          {props?.name || props?.investor || props?.associateRef?.investor}
        </Link>
      </TableCell>

      {projects?.map((project) => (
        <TableCell key={project._id} sx={{ width: "50px" }}>
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
          >
            {!!list
              .find((mapProject) => mapProject.projectRef === project._id)
              ?.associates?.find(
                (mapAssociante) => mapAssociante.associateRef._id === props?._id
              ) ? (
              <CheckCircleOutlineRoundedIcon sx={{ color: "green" }} />
            ) : (
              <CloseRoundedIcon fontSize="40px" color="secondary" />
            )}
          </Box>
        </TableCell>
      ))}
      <TableCell>
        <Tooltip title={`copiar ${props?._id ?? ""}`}>
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
            sx={{ cursor: "pointer" }}
            onClick={handleCopy}
          >
            <Snackbar
              open={open}
              autoHideDuration={500}
              onClose={handleClose}
              message="Copiado"
            />
            <Typography component={"p"} variant="caption">
              {props?._id?.substring(0, 4)}....
            </Typography>
            <CopyAll sx={{ fontSize: "20px" }}></CopyAll>
          </Box>
        </Tooltip>
      </TableCell>
      {/* <TableCell onClick={handleDelete}>
        <IconButton>
          <Delete />
        </IconButton>
      </TableCell> */}
    </TableRow>
  );
};

export default AssociateListItem;
