import { useState } from "react";
import {
  Paper,
  Collapse,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const styles = {
  paper: {
    marginBottom: "1rem",
  },
  header: {
    padding: "1rem",
    display: "flex",
    justifyContent: "around",
  },
  title: {
    flex: 1,
  },
  container: {
    padding: "1rem",
    paddingTop: "0",
  },
  inputWrapper: {
    paddingX: "0.5rem",
  },
  actions: {
    display: "flex",
    justifyContent: "space-around",
  },
};

const AssociatesFilter = (props) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  return (
    <Paper elevation={3} sx={styles.paper}>
      <Box sx={styles.header} onClick={toggleOpen}>
        <Typography variant="h6" fontWeight="bold" sx={styles.title}>
          Filtro
        </Typography>
        <ArrowDropDownIcon />
      </Box>
      <Collapse in={open}>
        <Grid
          container
          component="form"
          sx={styles.container}
          onSubmit={props.onSubmit}
          spacing={2}
        >
          <Grid item xs={12} sm={6} md={8} sx={styles.inputWrapper}>
            <TextField
              fullWidth
              label="Nombre"
              name="name"
              value={props.name}
              onChange={props.onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={styles.actions}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button type="submit" fullWidth>
                  Buscar
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button fullWidth onClick={props.onClear} color="neutral">
                  Limpiar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
    </Paper>
  );
};

export default AssociatesFilter;
