import { Box, Button, Card, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDistributions } from "../../../../../../../api/distributionsApi";
import { handleError } from "../../../../../../../slices/errorsSlice";
import { Link } from "react-router-dom";
import { getReports } from "../../../../../../../api/reportsApi";
import { currencyFormatter } from "../../../../../../../utils/formatUtils";
import { AttachFile, AttachFileOutlined } from "@mui/icons-material";
import UploadIcon from "@mui/icons-material/Upload";
import { createNewReport } from "../../../../../../../actions/reportsActions";
const { REACT_APP_HOST_URL } = process.env;
// const defaultReport = {
//   name: "",
//   period: "",
//   date: new Date().toISOString().slice(0, 10),
//   type: "financiero",
// };

function DataPresentation({ title, content }) {
  return (
    <Grid
      item
      sx={{
        maxWidth: {
          xs: "calc(100%/2)",
          sm: "calc(100%/6)",
          md: "calc(100%/10)",
          lg: "calc(100%/10)",
        },
      }}
    >
      <TextField
        disabled
        type="text"
        label={title}
        name={title}
        value={content}
      />
    </Grid>
  );
}

function ProjectReportsInvestorItem({ investorItem, index }) {
  const [loading, setLoading] = useState(false);
  const [lastDistribution, setLastDistribution] = useState(null);
  const [localReport, setLocalReport] = useState();
  const [report, setReport] = useState({
    name: "",
    period: lastDistribution?.period ?? "",
    date:
      lastDistribution?.date ??
      new Date(lastDistribution?.date ?? new Date()).toISOString().slice(0, 10),
    type: "financiero",
  });

  const [file, setFile] = useState(null);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = () => {
      const associateRef = investorItem.associateRef._id;
      const projectRef = investorItem.projectRef._id;
      getDistributions(token, {
        associateRef,
        projectRef,
        associateInvestmentId: investorItem._id,
      })
        .then((response) =>
          response.status === 200 ? response.json() : Promise.reject(response)
        )
        .then((data) => {
          const dist = data?.data?.distributions;

          console.log(
            `GET LAST Distribution: ${investorItem?.associateRef?.name ?? ""}`
          );
          console.log(dist[dist.length - 1]);
          setLastDistribution(dist[dist.length - 1]);
          getReports(token, {
            associateRef,
            projectRef,
            associateInvestmentId: investorItem._id,
          })
            .then((response) =>
              response.status === 200
                ? response.json()
                : Promise.reject(response)
            )
            .then((data) => {
              console.log(
                `GET Reports: ${investorItem?.associateRef?.name ?? ""}`
              );
              console.log(data);
              const reports = data?.reports ?? [];
              setLocalReport(
                reports.find(
                  (rep) => rep.distributionRef === dist[dist.length - 1]._id
                )
              );
            })
            .catch((err) => dispatch(handleError(err)));
        })
        .catch((err) => dispatch(handleError(err)))
        .finally(() => setLoading(false));
    };
    setLoading(true);
    fetchData();
  }, []);

  const handleChange = (e) =>
    setReport({ ...report, [e.target.name]: e.target.value });

  const handleFileChange = (e) => {
    if (!e.target.files[0]) return;
    setFile(e.target.files[0]);
    setReport({
      ...report,
      name: e.target.files[0]?.name ?? "",
      period: lastDistribution?.period ?? "",
      date: lastDistribution?.date ?? "",
    });
  };

  const handleSubmit = () => {
    const associateRef = investorItem.associateRef._id;
    const projectRef = investorItem.projectRef._id;
    setLoading(true);
    dispatch(
      createNewReport({
        report: {
          ...report,
          distributionRef: lastDistribution._id,
          projectRef: projectRef,
          associateRef: associateRef,
          date: new Date(report.date).toISOString().replace("T00", "T06"),
          investmentRef: investorItem._id,
        },
        file,
        query: {
          projectRef: projectRef,
          associateRef: associateRef,
          associateInvestmentId: investorItem._id,
        },
        callback: () => {
          getReports(token, {
            associateRef,
            projectRef,
            associateInvestmentId: investorItem._id,
          })
            .then((response) =>
              response.status === 200
                ? response.json()
                : Promise.reject(response)
            )
            .then((data) => {
              console.log(
                `GET Reports: ${investorItem?.associateRef?.name ?? ""}`
              );
              console.log(data);
              const reports = data?.reports ?? [];
              setLocalReport(
                reports.find(
                  (rep) => rep.distributionRef === lastDistribution._id
                )
              );
            })
            .catch((err) => dispatch(handleError(err)));
        },
      })
    );
  };

  console.log({ lastDistribution, loading, localReport });
  return (
    <Card elevation={1} sx={{ px: 1, py: 1.5, my: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Typography variant="body1" component={"p"} fontWeight={"bold"}>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={`/dashboard/asociados/${investorItem.associateRef?._id}/inversion/${investorItem._id}`}
            >
              {investorItem?.associateRef?.name ?? ""}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} lg={9} display={"flex"}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
            p={1}
          >
            <Typography
              variant="body1"
              component={"p"}
              fontWeight={"bold"}
              textAlign={"center"}
            >
              {index ?? 0}
            </Typography>
          </Box>
          <Grid flex={1} container spacing={1} justifyContent={"center"}>
            <DataPresentation
              title={"Fecha"}
              content={lastDistribution?.date?.substring(0, 10) ?? ""}
            />
            <DataPresentation
              title={"Periodo"}
              content={lastDistribution?.period ?? ""}
            />
            <DataPresentation
              title={"Monto"}
              content={currencyFormatter.format(lastDistribution?.amount)}
            />
            <DataPresentation
              title={"Fees"}
              content={currencyFormatter.format(lastDistribution?.fees)}
            />
            <DataPresentation
              title={"Retención impto"}
              content={currencyFormatter.format(lastDistribution?.retentionTax)}
            />
            <DataPresentation
              title={"Distribución neta"}
              content={currencyFormatter.format(
                lastDistribution?.netDistribution
              )}
            />
            <DataPresentation
              title={"Distribucion acum."}
              content={currencyFormatter.format(
                lastDistribution?.accumulatedDistribution
              )}
            />
            <DataPresentation
              title={"Fees acum."}
              content={currencyFormatter.format(
                lastDistribution?.accumulatedFess
              )}
            />
            <DataPresentation
              title={"Retencion acum."}
              content={currencyFormatter.format(lastDistribution?.retention)}
            />
            <DataPresentation
              title={"Acumulado Neto"}
              content={currencyFormatter.format(
                lastDistribution?.netAccumulated
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          {localReport != null ? (
            <Box
              display={"flex"}
              alignContent={"center"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <a
                href={`${REACT_APP_HOST_URL}/images/reports/${
                  localReport?.path ?? "undefined"
                }`}
                target="_blank"
                rel="noreferrer"
              >
                <Button fullWidth size={"small"} color="info">
                  <AttachFileOutlined></AttachFileOutlined>
                  {/* {localReport?.name ?? ""} */}
                </Button>
              </a>
            </Box>
          ) : (
            <Grid container justifyContent={"center"}>
              <Grid item xs={6} md={4}>
                <TextField
                  label="Nombre"
                  name="name"
                  disabled
                  value={report?.name}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6} md={3} textAlign={"center"}>
                <>
                  {!file ? (
                    <Button variant="text" component="label" color="info">
                      <input hidden type="file" onChange={handleFileChange} />
                      <UploadIcon />
                    </Button>
                  ) : (
                    <Button variant="text" component="label" color="info">
                      <input hidden type="file" onChange={handleFileChange} />
                      <AttachFile />
                    </Button>
                  )}
                </>
              </Grid>
              <Grid item xs={6} md={5}>
                <Button
                  fullWidth
                  disabled={
                    !report?.name || !report?.period || !report?.date || !file
                  }
                  onClick={handleSubmit}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

export default ProjectReportsInvestorItem;
