import { Grid, Typography } from "@mui/material";
import React from "react";

function PageTitle({ name, id }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={8}>
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ marginBottom: "1rem" }}
        >
          Proyecto {name ?? id} - REPORTES
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}></Grid>
    </Grid>
  );
}

export default PageTitle;
