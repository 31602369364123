import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import InvestorsListItem from './InvestorsListItem';

const InvestorsListTable = props => {
  return (
    <TableContainer component={Paper} style={{ maxHeight: "50vh" }}>
      <Table>
        <TableHead color="primary">
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>Contacto</TableCell>
            <TableCell>Proyecto</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.investors?.map(investor => <InvestorsListItem key={investor._id} {...investor} onDelete={props.onDelete} handleCheckboxChange={(checked)=>{
            props.handleCheckboxChange(investor?.associateRef?._id, checked)
          }}/>)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default InvestorsListTable;

