import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';

import { login } from '../../actions/authActions';

const styles = {
  container: {
    height: '100vh'
  },
  bgImage: {
    backgroundImage: 'url(/img/login.jpg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh'
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  logo: {
    marginBottom: '2rem'
  }
}

const Login = () => {
  const [state, setState] = useState({ user: '', password: '' });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(login({ ...state, callback: handleLogin }));
  }

  const handleLogin = () => {
    navigate('/dashboard');
  }

  return (
    <Box sx={styles.container}>
      <Grid container>
        <Grid item xs={12} md={6} sx={styles.form}>
          <Box sx={{ px: { xs: '2rem', md: '5rem' } }}>
            <img alt="IDE Fund Logo" src="/img/IDE.png" style={styles.logo} />
            <Grid container padding={2} component="form" onSubmit={handleSubmit}>
              <Grid item xs={12} textAlign="center" marginBottom={2}>
                <Typography variant="h4">Inicia sesión</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField label="Usuario" name="user" value={state.user} onChange={handleChange} />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Contraseña" type="password" name="password" value={state.password} onChange={handleChange} />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button type="submit">Entrar</Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={0} md={6} sx={styles.bgImage}></Grid>
      </Grid>
    </Box>
  );
}

export default Login;
