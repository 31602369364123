import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProjectReportsInvestorItem from "./ProjectReportsInvestorItem";

function ProjectReportInvestors() {
  const investments = useSelector((state) => state.investments.list);

  const [action, setAction] = useState(false);
  const [masterLoading, setMasterLoading] = useState(investments?.length ?? 0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loading) {
      return;
    }
    if (masterLoading <= 0) {
      setLoading(false);
      setAction(false);
    }
  }, [masterLoading, loading]);

  return (
    <Box my={2}>
      <Typography>Lista de inversionistas del proyecto</Typography>
      <Box>
        {investments?.map((investor, index) => (
          <Box key={investor?._id}>
            <ProjectReportsInvestorItem
              action={action}
              setMasterLoading={setMasterLoading}
              investorItem={investor}
              index={index + 1}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default ProjectReportInvestors;
