import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, Button, DialogActions, Typography, IconButton, Divider, Grid, TextField } from '@mui/material';
import CurrencyField from '../../../../components/inputs/CurrencyField';
import PercentageField from '../../../../components/inputs/PercentageField';

import { createNewLoan, getLoansList } from '../../../../actions/loansActions';

const defaultState = {
  amount: '',
  deadline: '',
  rate: '',
  amortization: '',
  gracePeriod: '',
  depositDate: new Date().toISOString().slice(0, 10),
};

const styles = {
  container: {
    display: 'block',
  },
  content: {
    paddingY: 0,
  },
  actions: {
    paddingRight: '1rem'
  },
  title: {
    my: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fieldWrapper: {
    px: '1rem'
  }
}

const ProjectDetailsLoanCreate = props => {
  const [loan, setLoan] = useState({ ...defaultState });

  const dispatch = useDispatch();
  const params = useParams();

  const handleChange = e => setLoan({ ...loan, [e.target.name]: e.target.rawValue ?? e.target.value });

  const handleClose = e => {
    e?.preventDefault();
    setLoan({ ...defaultState });
    props.onClose();
  }

  const handleSubmit = e => {
    dispatch(createNewLoan({ loan: { ...loan, projectRef: params.id }, callback: () => {
      handleClose();
      dispatch(getLoansList({ query: { projectRef: params.id } }));
    } }));
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>
       <Box sx={styles.title}>
          <Typography variant="h5">Crear crédito</Typography>
          <IconButton onClick={handleClose}><Close /></IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container>
          <CurrencyField label="Monto" name="amount" value={loan.amount} onChange={handleChange} />
          <TextField label="Plazo" name="deadline" value={loan.deadline} onChange={handleChange} />
          <PercentageField label="Tasa" name="rate" value={loan.rate} onChange={handleChange} />
          <CurrencyField label="Amortización" name="amortization" value={loan.amortization} onChange={handleChange} />
          <TextField label="Periodo de gracia" name="gracePeriod" value={loan.gracePeriod} onChange={handleChange} />
          <TextField type="date" label="Fecha de disposición" name="depositDate" value={loan.depositDate} onChange={handleChange} />
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button onClick={handleSubmit}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProjectDetailsLoanCreate;
