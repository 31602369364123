import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  current: {}
}

const feesSlice = createSlice({
  name: 'fees',
  initialState,
  reducers: {
    setFeesList: (state, { payload }) => ({ ...state, list: [...payload.fees] }),
    setCurrentFee: (state, { payload }) => ({ ...state, current: { ...payload.fee } }),
    resetFeesState: () => ({ list: [], current: { } })
  }
});

export const { setFeesList, setCurrentFee, resetFeesState } = feesSlice.actions;
export default feesSlice.reducer;
