import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCurrentProject } from "../../../../../actions/projectsActions";
import { resetProjectsState } from "../../../../../slices/projectsSlice";
import PageTitle from "./components/PageTitle";

import { getInvestmentsList } from "../../../../../actions/investmentsActions";
import ProjectReportInvestors from "./components/ProjectInvestors/ProjectReportInvestors";

function ImportLastReportePage() {
  const params = useParams();

  const dispatch = useDispatch();
  const current = useSelector((state) => state.projects.current);

  useEffect(() => {
    dispatch(getCurrentProject({ id: params.id }));
    dispatch(
      getInvestmentsList({ query: { projectRef: params.id, results: 999999 } })
    );
    return () => dispatch(resetProjectsState());
  }, []);

  return (
    <div>
      <PageTitle name={current.name} id={params.id} />
      {/* <ImportExcelButton uploadHandler={handleFile} /> */}
      <ProjectReportInvestors />
      {/* <Button onClick={createRequests}>Create</Button> */}
    </div>
  );
}

export default ImportLastReportePage;
