import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createAssociates,
  createAssociatesDistribution,
  createAssociatesInterest,
  deleteAssociates,
  getAssociateById,
  getAssociates,
  updateAssociates,
} from "../api/associatesApi";
import { showAlert } from "../slices/alertsSlice";
import { setAssociateList, setCurrentAssociate } from "../slices/asociateSlice";
import { handleError } from "../slices/errorsSlice";
import { startLoading, endLoading } from "../slices/loadingSlice";
import { getInterestsList } from "./interestsActions";
import { getDistributionsList } from "./distributionsActions";

export const getAssociatesList = createAsyncThunk(
  "associates/getAssociatessList",
  ({ query }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());
    const results = 10000;
    getAssociates(token, query, results)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) =>
        dispatch(setAssociateList({ associates: data.associates }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const getCurrentAssociates = createAsyncThunk(
  "associates/getCurrentAssociates",
  ({ id }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getAssociateById(token, id)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) =>
        dispatch(setCurrentAssociate({ investor: data.associate }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const createNewAssociates = createAsyncThunk(
  "associates/createNewAssociates",
  ({ investor, callback }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    createAssociates(token, investor)
      .then((response) =>
        response.status === 201 ? response.json() : Promise.reject(response)
      )
      .then((data) => callback(data.associate_id))
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const updateCurrentAssociates = createAsyncThunk(
  "associates/updateCurrentAssociates",
  ({ id, investor }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    updateAssociates(token, id, investor)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) =>
        dispatch(setCurrentAssociate({ investor: data.associate }))
      )
      .then(() =>
        dispatch(
          showAlert({ message: "Inversionista actualizado", type: "success" })
        )
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const createNewAssociatesDistribution = createAsyncThunk(
  "distributions/createNewAssociatesDistribution",
  ({ id, distribution, query, callback }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    createAssociatesDistribution(token, id, distribution)
      .then((response) =>
        response.status === 201 ? response.json() : Promise.reject(response)
      )
      .then((data) => {
        return callback(data.distribution_id);
      })
      .then(() =>
        dispatch(showAlert({ message: "Distribución creada", type: "success" }))
      )
      .then(() => dispatch(getDistributionsList({ query })))
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const createNewAssociatesInterest = createAsyncThunk(
  "distributions/createNewAssociatesInterest",
  ({ id, interest, query, callback }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    createAssociatesInterest(token, id, interest)
      .then((response) =>
        response.status === 201 ? response.json() : Promise.reject(response)
      )
      .then(() =>
        dispatch(showAlert({ message: "Interés creado", type: "success" }))
      )
      .then((data) => callback(data.distribution))
      .then(() => dispatch(getInterestsList({ query })))
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const deleteCurrentAssociates = createAsyncThunk(
  "associates/deleteCurrentAssociates",
  ({ id, callback }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    deleteAssociates(token, id)
      .then((response) =>
        response.status === 200 ? true : Promise.reject(response)
      )
      .then(() => callback())
      .then(() =>
        dispatch(showAlert({ message: "Proyecto borrado", type: "success" }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);
