import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from "@mui/material";
import React from "react";
import CurrencyField from "../../../../../components/inputs/CurrencyField";
import PercentageField from "../../../../../components/inputs/PercentageField";
import { useParams } from "react-router-dom";

const styles = {
  inputWrapper: {
    margin: "0.5rem 1rem",
  },
  submit: {
    textAlign: "center",
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
};

const InvestorProjectDetailsInvesment = (props) => {
  let { investmentId } = useParams();

  const investments = Object.values(props.investments);
  const investmentToShow = investments.find((i) => i._id === investmentId);

  return (
    <Card>
      <CardHeader title="Datos de las inversiónes" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            {props.investments && (
              <>
                <Box sx={styles.inputWrapper}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Inversionista"
                    name="investor"
                    value={investmentToShow?.investor || ""}
                    onChange={(e) => props.onChange(e)}
                  />
                </Box>
                <Box sx={styles.inputWrapper}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Vehículo de inversión"
                    name="vehiculo"
                    value={investmentToShow?.vehiculo || ""}
                    onChange={(e) => props.onChange(e)}
                  />
                </Box>
                <Box sx={styles.inputWrapper}>
                  <CurrencyField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Capital aportado total"
                    name="capital"
                    value={investmentToShow?.capital || ""}
                    onChange={(e) => props.onChange(e)}
                  />
                </Box>
                <Box sx={styles.inputWrapper}>
                  <CurrencyField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Capital aportado al momento"
                    name="capitalNow"
                    value={investmentToShow?.capitalNow || ""}
                    onChange={(e) => props.onChange(e)}
                  />
                </Box>
                <Box sx={styles.inputWrapper}>
                  <PercentageField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Participación"
                    name="participation"
                    value={investmentToShow?.participation || ""}
                    onChange={(e) => props.onChange(e)}
                  />
                </Box>
                <Box sx={styles.inputWrapper}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Crédito"
                    name="credit"
                    value={investmentToShow?.credit?.credit}
                    onChange={(e) => props.onChange(e)}
                  />
                </Box>
                <Box sx={styles.inputWrapper}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    label="Fecha de inversión"
                    name="investmentDate"
                    value={
                      investmentToShow?.investmentDate
                        ? new Date(investmentToShow?.investmentDate)
                            .toISOString()
                            .slice(0, 10)
                        : new Date().toISOString()
                    }
                    onChange={(e) => props.onChange(e)}
                  />
                </Box>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box sx={styles.submit}>
              <Button onClick={props.onSubmit}>Guardar</Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default InvestorProjectDetailsInvesment;
