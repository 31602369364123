import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, Button, DialogActions, Typography, IconButton, Divider, Grid, TextField } from '@mui/material';
import CurrencyField from '../../../../components/inputs/CurrencyField';

import { createNewProjectInterest, getCurrentProjectInterests } from '../../../../actions/projectsActions';

const defaultState = {
  date: new Date().toISOString().slice(0, 10),
  period: '',
  amount: '',
  fees: '',
  netDistribution: '',
  mxn: '',
  change: '',
  accumulatedDistribution: '',
  accumulatedFess: '',
  retention: '',
  netAccumulated: ''
};

const styles = {
  container: {
    display: 'block',
  },
  content: {
    paddingY: 0,
  },
  actions: {
    paddingRight: '1rem'
  },
  title: {
    my: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fieldWrapper: {
    px: '1rem'
  }
}

const ProjectDetailsInterestCreate = props => {
  const [interest, setInterest] = useState({ ...defaultState });

  const dispatch = useDispatch();
  const params = useParams();

  const handleChange = e => setInterest({ ...interest, [e.target.name]: e.target.rawValue ?? e.target.value });

  const handleClose = e => {
    e?.preventDefault();
    setInterest({ ...defaultState });
    props.onClose();
  }

  const handleSubmit = e => {
    dispatch(createNewProjectInterest({ id: params.id, interest, callback: () => {
      handleClose();
      dispatch(getCurrentProjectInterests({ id: params.id }));
    } }));
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>
       <Box sx={styles.title}>
          <Typography variant="h5">Crear interés</Typography>
          <IconButton onClick={handleClose}><Close /></IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container>
          <TextField type="date" label="Fecha" name="date" value={interest.date} onChange={handleChange} />
          <TextField label="Periodo" name="period" value={interest.period} onChange={handleChange} />
          <CurrencyField label="Monto" name="amount" value={interest.amount} onChange={handleChange} />
          <CurrencyField label="Fees"  name="fees" value={interest.fees} onChange={handleChange} />
          <CurrencyField label="Distribución neta" name="netDistribution" value={interest.netDistribution} onChange={handleChange} />
          <CurrencyField label="Pago en MXN" name="mxn" value={interest.mxn} onChange={handleChange} />
          <CurrencyField label="Tipo de cambio" name="change" value={interest.change} onChange={handleChange} />
          <CurrencyField label="Distribución acumulada" name="accumulatedDistribution" value={interest.accumulatedDistribution} onChange={handleChange} />
          <CurrencyField label="Fees acumulados" name="accumulatedFess" value={interest.accumulatedFess} onChange={handleChange} />
          <CurrencyField label="Retenciones acumuladas" name="retention" value={interest.retention} onChange={handleChange} />
          <CurrencyField label="Neto acumulado" name="netAccumulated" value={interest.netAccumulated} onChange={handleChange} />
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button onClick={handleSubmit}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProjectDetailsInterestCreate;
