import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";

import { getInvestmentsList } from "../../../actions/investmentsActions";
import { resetInvestmentsState } from "../../../slices/investmentsSlice";
import InvestorsFilter from "../Investors/InvestorsFilter";
import InvestorsListTable from "../Investors/InvestorsListTable";
import AppPagination from "../../../components/AppPagination";

const defaultFilter = {
  associate: "",
  project: "",
};

const InvestmentPage = () => {
  const dispatch = useDispatch();
  const investors = useSelector((state) => state.investments.list);

  const [filter, setFilter] = useState({ ...defaultFilter });
  const [pagination, setPagination] = useState({ page: 1 });

  useEffect(() => {
    setPagination({ page: 1 });
    return () => dispatch(resetInvestmentsState());
  }, []);

  useEffect(() => {
    const f = { page: pagination.page };

    filter.associate.length > 0 ? (f.associate = filter.associate) : null;
    filter.project.length > 0 ? (f.project = filter.project) : null;

    dispatch(getInvestmentsList({ query: f }));
  }, [pagination]);

  const handleChange = (e) =>
    setFilter({ ...filter, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    setPagination({ page: 1 });
  };

  const onClear = () => {
    setFilter({ ...defaultFilter });
    setPagination({ page: 1 });
  };

  const nextPage = () => setPagination({ page: pagination.page + 1 });
  const prevPage = () =>
    setPagination({ page: Math.max(pagination.page - 1, 1) });

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ marginBottom: "1rem" }}
        >
          Inversiones
        </Typography>
      </Box>
      <InvestorsFilter
        {...filter}
        onChange={handleChange}
        onSubmit={onSubmit}
        onClear={onClear}
      />
      <InvestorsListTable investors={investors} onDelete={onClear} />
      <AppPagination page={pagination.page} prev={prevPage} next={nextPage} />
    </>
  );
};

export default InvestmentPage;
