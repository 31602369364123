import { useDispatch } from "react-redux";
import { Delete } from "@mui/icons-material";
import { TableRow, TableCell, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { currencyFormatter } from "../../../utils/formatUtils";
import { deleteCurrentInvestment } from "../../../actions/investmentsActions";

const InvestorsListItem = (props) => {
  const dispatch = useDispatch();

  const handleDelete = (e) => {
    e?.preventDefault();
    dispatch(
      deleteCurrentInvestment({
        id: props._id,
        callback: () => props.onDelete(),
      })
    );
  };

  return (
    <TableRow>
      <TableCell>
        <Link to={props.associateRef?._id}>
          {props.investor ||
            props.associateRef?.investor ||
            props.associateRef?.name}
        </Link>
      </TableCell>
      <TableCell>{props.associateRef?.name}</TableCell>
      <TableCell>{props.projectRef?.name}</TableCell>
      <TableCell>
        {new Date(
          `${props.investmentDate?.slice?.(0, 10)}T00:00:00`
        )?.toLocaleDateString()}
      </TableCell>
      <TableCell>{currencyFormatter.format(props.capital)}</TableCell>
      <TableCell onClick={handleDelete}>
        <IconButton>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default InvestorsListItem;
