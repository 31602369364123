import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createInvestor,
  createInvestorDistribution,
  createInvestorInterest,
  deleteInvestor,
  getInvestor,
  getInvestors,
  updateInvestor,
} from "../api/investorsApi";
import { showAlert } from "../slices/alertsSlice";
import { setCurrentInvestor, setInvestorsList } from "../slices/investorsSlice";
import { handleError } from "../slices/errorsSlice";
import { startLoading, endLoading } from "../slices/loadingSlice";
import { getInterestsList } from "./interestsActions";
import { getDistributionsList } from "./distributionsActions";

export const getInvestorsList = createAsyncThunk(
  "investors/getInvestorsList",
  ({ query }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getInvestors(token, query)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) =>
        dispatch(setInvestorsList({ investors: data.associates }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const getCurrentInvestor = createAsyncThunk(
  "investors/getCurrentInvestor",
  ({ id }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getInvestor(token, id)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) =>
        dispatch(setCurrentInvestor({ investor: data.associate }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const createNewInvestor = createAsyncThunk(
  "investors/createNewInvestor",
  ({ investor, callback }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    createInvestor(token, investor)
      .then((response) =>
        response.status === 201 ? response.json() : Promise.reject(response)
      )
      .then((data) => callback(data.associate_id))
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const updateCurrentInvestor = createAsyncThunk(
  "investors/updateCurrentInvestor",
  ({ id, investor }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    updateInvestor(token, id, investor)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) =>
        dispatch(setCurrentInvestor({ investor: data.associate }))
      )
      .then(() =>
        dispatch(
          showAlert({ message: "Inversionista actualizado", type: "success" })
        )
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const createNewInvestorDistribution = createAsyncThunk(
  "distributions/createNewInvestorDistribution",
  ({ id, distribution, query, callback }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    createInvestorDistribution(token, id, distribution)
      .then((response) =>
        response.status === 201 ? response.json() : Promise.reject(response)
      )
      .then((data) => {
        return callback(data.distribution_id);
      })
      .then(() =>
        dispatch(showAlert({ message: "Distribución creada", type: "success" }))
      )
      .then(() => dispatch(getDistributionsList({ query })))
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const createNewDistributionByInvestor = createAsyncThunk(
  "distributions/createNewInvestorDistribution",
  ({ id, distribution, callback }, { getState, dispatch }) => {
    const { token } = getState().auth;
    createInvestorDistribution(token, id, distribution)
      .then((response) =>
        response.status === 201 ? response.json() : Promise.reject(response)
      )
      .then(() => {
        return callback();
      })
      .then(() =>
        dispatch(showAlert({ message: "Distribución creada", type: "success" }))
      )
      .catch((err) => dispatch(handleError(err)));
  }
);

export const createNewInvestorInterest = createAsyncThunk(
  "distributions/createNewInvestorInterest",
  ({ id, interest, query, callback }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    createInvestorInterest(token, id, interest)
      .then((response) =>
        response.status === 201 ? response.json() : Promise.reject(response)
      )
      .then(() =>
        dispatch(showAlert({ message: "Interés creado", type: "success" }))
      )
      .then((data) => callback(data.distribution))
      .then(() => dispatch(getInterestsList({ query })))
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const deleteCurrentInvestor = createAsyncThunk(
  "investors/deleteCurrentInvestor",
  ({ id, callback }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    deleteInvestor(token, id)
      .then((response) =>
        response.status === 200 ? true : Promise.reject(response)
      )
      .then(() => callback())
      .then(() =>
        dispatch(showAlert({ message: "Proyecto borrado", type: "success" }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);
