import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";

import DitributionsItem from "./DitributionsItem";
import NewInvestorProjectDetailsDistributionCreate from "./NewInvestorProjectDetailsDistributionCreate";

const styles = {
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

function NewInvestorProjectDetailDistributions({ report }) {
  const distributions = useSelector((state) => state.distributions.list);
  const reports = useSelector((state) => state.reports.list);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  console.log({ distributions });

  useEffect(() => {
    if (report == null) {
      console.log("New report");
      return;
    }
    // console.log(report);
  }, []);

  return (
    <>
      <Box sx={styles.title}>
        <Typography variant="h5">Pago de distribuciones</Typography>
        <Button onClick={handleOpen}>Añadir</Button>
      </Box>
      <Box>
        {distributions.length === 0 && (
          <Card>
            <CardContent>
              <Typography variant="p">Sin distribuciones</Typography>
            </CardContent>
          </Card>
        )}
        {distributions.map((distribution, index) =>
          index < 1 ? (
            <DitributionsItem
              key={distribution._id}
              distribution={distribution}
              index={index}
              report={reports.find(
                (report) => report.distributionRef === distribution._id
              )}
            />
          ) : (
            <DitributionsItem
              key={distribution._id}
              distribution={distribution}
              index={index}
              report={reports.find(
                (report) => report.distributionRef === distribution._id
              )}
              lastDistribution={distributions[index - 1]}
            />
          )
        )}
      </Box>
      <NewInvestorProjectDetailsDistributionCreate
        open={open}
        onClose={handleClose}
      />
    </>
  );
}

export default NewInvestorProjectDetailDistributions;
