/* eslint-disable no-useless-computed-key */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AttachFile, Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  Typography,
  IconButton,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import CurrencyField from "../../../../../../components/inputs/CurrencyField";

import { createNewInvestorDistribution } from "../../../../../../actions/investorsActions";
import { useParams } from "react-router-dom";
import {
  createNewReport,
  getReportsList,
} from "../../../../../../actions/reportsActions";
import { getDistributionsList } from "../../../../../../actions/distributionsActions";

const defaultState = {
  date: new Date().toISOString().slice(0, 10),
  period: "",
  amount: "0",
  retentionTax: "0",
  fees: "0",
  netDistribution: "0",
  mxn: "0",
  change: "0",
  accumulatedDistribution: "0",
  accumulatedFess: "0",
  retention: "0",
  netAccumulated: "0",
};

const styles = {
  container: {
    display: "block",
  },
  content: {
    paddingY: 0,
  },
  actions: {
    paddingRight: "1rem",
  },
  title: {
    my: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fieldWrapper: {
    px: "1rem",
  },
};

const NewInvestorProjectDetailsDistributionCreate = (props) => {
  let { investmentId } = useParams();
  const current = useSelector((state) => state.investments.current);
  const distributionsList = useSelector((state) => state.distributions.list);

  const [file, setFile] = useState(null);
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setReport({
      ...report,
      ["name"]: e.target.files[0].name,
    });
  };

  const [report, setReport] = useState({
    name: "",
    period: "",
    date: new Date().toISOString().slice(0, 10),
    type: "financiero",
  });

  const handleReportChange = (e) =>
    setReport({
      ...report,
      [e.target.name]: e.target.rawValue ?? e.target.value,
    });

  const [distribution, setDistribution] = useState({ ...defaultState });

  const dispatch = useDispatch();

  const handleChange = (e) =>
    setDistribution({
      ...distribution,
      [e.target.name]: e.target.rawValue ?? e.target.value,
    });

  const handleClose = (e) => {
    // e?.preventDefault();
    setDistribution({ ...defaultState });
    setReport({
      name: "",
      period: "",
      date: new Date().toISOString().slice(0, 10),
      type: "financiero",
    });
    setFile(null);
    props.onClose();
    const associateRef = current[0]?.associateRef._id;
    const projectRef = current[0]?.projectRef._id;
    dispatch(
      getDistributionsList({
        query: {
          associateRef,
          projectRef,
          associateInvestmentId: investmentId,
        },
      })
    );
    dispatch(
      getReportsList({
        query: {
          associateRef,
          projectRef,
          associateInvestmentId: investmentId,
        },
      })
    );
  };

  // const handleSubmit = (e) => {
  //   e?.preventDefault();
  //   dispatch(
  //     createNewInvestorDistribution({
  //       id: current[0].associateRef._id,
  //       distribution: {
  //         ...distribution,
  //         projectRef: current[0].projectRef._id,
  //         investmentRef: investmentId,
  //       },
  //       query: {
  //         projectRef: current[0].projectRef._id,
  //         associateRef: current[0].associateRef._id,
  //         associateInvestmentId: investmentId,
  //       },
  //       callback: handleClose,
  //     })
  //   );
  // };

  const uploadReport = (distributionId) => {
    dispatch(
      createNewReport({
        report: {
          ...report,
          period: distribution.period,
          distributionRef: distributionId,
          projectRef: current[0]?.projectRef?._id,
          associateRef: current[0]?.associateRef?._id,
          date: new Date(distribution.date).toISOString().replace("T00", "T06"),
          investmentRef: investmentId,
        },
        file,
        query: {
          projectRef: current[0]?.projectRef?._id,
          associateRef: current[0]?.associateRef?._id,
          associateInvestmentId: investmentId,
        },
        callback: handleClose,
      })
    );

    setFile(null);
    setReport({
      name: "",
      period: "",
      date: new Date().toISOString().slice(0, 10),
      type: "informativo",
    });
  };

  const handleUpLoadSubmit = (e) => {
    e?.preventDefault();

    dispatch(
      createNewInvestorDistribution({
        id: current[0].associateRef._id,
        distribution: {
          ...distribution,
          projectRef: current[0].projectRef._id,
          investmentRef: investmentId,
        },
        query: {
          projectRef: current[0].projectRef._id,
          associateRef: current[0].associateRef._id,
          associateInvestmentId: investmentId,
        },
        callback: uploadReport,
      })
    );
  };

  const handleUpLoadWithoutReportSubmit = (e) => {
    e?.preventDefault();

    dispatch(
      createNewInvestorDistribution({
        id: current[0].associateRef._id,
        distribution: {
          ...distribution,
          projectRef: current[0].projectRef._id,
          investmentRef: investmentId,
        },
        query: {
          projectRef: current[0].projectRef._id,
          associateRef: current[0].associateRef._id,
          associateInvestmentId: investmentId,
        },
        callback: handleClose,
      })
    );
  };

  const { amount, fees, retentionTax, netDistribution } = distribution;

  useEffect(() => {
    setDistribution((prevState) => ({
      ...prevState,
      netDistribution: Number(amount) - Number(fees) - Number(retentionTax),
    }));
  }, [amount, fees, retentionTax]);

  useEffect(() => {
    const lastAccumulatedDistribution =
      distributionsList.length > 0
        ? distributionsList[distributionsList.length - 1]
            .accumulatedDistribution
        : "0";
    setDistribution((prevState) => ({
      ...prevState,
      accumulatedDistribution:
        Number(amount) + Number(lastAccumulatedDistribution),
    }));
  }, [amount, distributionsList]);

  useEffect(() => {
    const lastAccumulatedFess =
      distributionsList.length > 0
        ? distributionsList[distributionsList.length - 1].accumulatedFess
        : "0";
    setDistribution((prevState) => ({
      ...prevState,
      accumulatedFess: Number(fees) + Number(lastAccumulatedFess),
    }));
  }, [fees, distributionsList]);

  useEffect(() => {
    console.log("Retención Acumulada");
    const lastRetention =
      distributionsList.length > 0
        ? distributionsList[distributionsList.length - 1].retention
        : "0";
    setDistribution((prevState) => ({
      ...prevState,
      retention: Number(retentionTax) + Number(lastRetention),
    }));
  }, [retentionTax, distributionsList]);

  useEffect(() => {
    console.log("Distribución neta acumulada");

    const lastNetAccumulated =
      distributionsList.length > 0
        ? distributionsList[distributionsList.length - 1].netAccumulated
        : "0";
    setDistribution((prevState) => ({
      ...prevState,
      netAccumulated: Number(netDistribution) + Number(lastNetAccumulated),
    }));
  }, [netDistribution, distributionsList]);

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>
        <Box sx={styles.title}>
          <Typography variant="h5">Crear distribución</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={6}>
            <TextField
              type="date"
              label="Fecha"
              name="date"
              value={distribution.date}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Periodo"
              name="period"
              value={distribution.period}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <CurrencyField
              label="Monto"
              name="amount"
              value={distribution.amount}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <CurrencyField
              label="Fees"
              name="fees"
              value={distribution.fees}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <CurrencyField
              label="Retención de impuestos"
              name="retentionTax"
              value={distribution.retentionTax}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <CurrencyField
              disabled
              label="Distribución neta"
              name="netDistribution"
              value={distribution.netDistribution}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={6} md={4} lg={3}>
            <CurrencyField
              disabled
              label="Distribución acumulada"
              name="accumulatedDistribution"
              value={distribution.accumulatedDistribution}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <CurrencyField
              disabled
              label="Fees acumulados"
              name="accumulatedFess"
              value={distribution.accumulatedFess}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <CurrencyField
              disabled
              label="Retenciones acumuladas"
              name="retention"
              value={distribution.retention}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <CurrencyField
              disabled
              label="Neto acumulado"
              name="netAccumulated"
              value={distribution.netAccumulated}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        {/* <DialogActions sx={styles.actions}>
          <Button onClick={handleSubmit}>Guardar solo distribución</Button>
        </DialogActions> */}
        <Divider />
        <Typography mt={2} variant="h5">
          Agregar Reporte
        </Typography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              label="Nombre"
              name="name"
              disabled
              value={report.name}
              onChange={handleReportChange}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              label="Periodo"
              name="period"
              value={report.period}
              onChange={handleReportChange}
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              type="date"
              label="Fecha"
              name="date"
              value={report.date}
              onChange={handleReportChange}
            />
          </Grid> */}
          <Grid item xs={12} sm={6} md={12} lg={3} textAlign={"center"}>
            <>
              <Button variant="text" component="label">
                <input hidden type="file" onChange={handleFileChange} />
                <AttachFile />
              </Button>
              <Typography variant="caption">
                {file ? file.name : "Seleccionar archivo"}
              </Typography>
            </>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        {!file ? (
          <Button
            onClick={handleUpLoadWithoutReportSubmit}
            disabled={
              !distribution.date ||
              !distribution.period ||
              !distribution.amount ||
              !distribution.retentionTax ||
              !distribution.fees
            }
          >
            Guardar
          </Button>
        ) : (
          <Button
            onClick={handleUpLoadSubmit}
            disabled={
              !report.name ||
              !distribution.date ||
              !distribution.period ||
              !distribution.amount ||
              !distribution.retentionTax ||
              !distribution.fees ||
              !file
            }
          >
            Guardar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default NewInvestorProjectDetailsDistributionCreate;
