import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

const projectsAssociateSlice = createSlice({
  name: "projectAssociates",
  initialState,
  reducers: {
    setProjectsAssociateList: (state, { payload }) => ({
      ...state,
      list: [...payload.projects],
    }),
    resetProjectsState: () => ({ list: [], current: {} }),
  },
});

export const { setProjectsAssociateList, resetProjectsState } =
  projectsAssociateSlice.actions;
export default projectsAssociateSlice.reducer;
