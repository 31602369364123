import { combineReducers, configureStore } from "@reduxjs/toolkit";
import alertsSlice from "../slices/alertsSlice";
import authSlice from "../slices/authSlice";
import errorsSlice from "../slices/errorsSlice";
import loadingSlice from "../slices/loadingSlice";
import projectsSlice from "../slices/projectsSlice";
import investorsSlice from "../slices/investorsSlice";
import investmentsSlice from "../slices/investmentsSlice";
import distributionsSlice from "../slices/distributionsSlice";
import interestsSlice from "../slices/interestsSlice";
import partialsSlice from "../slices/partialsSlice";
import loansSlice from "../slices/loansSlice";
import feesSlice from "../slices/feesSlice";
import reportsSlice from "../slices/reportsSlice";
import asociateSlice from "../slices/asociateSlice";
import projectAssociatesSlice from "../slices/projectAssociatesSlice";

const rootReducer = combineReducers({
  auth: authSlice,
  associates: asociateSlice,
  projectAssociates: projectAssociatesSlice,
  alerts: alertsSlice,
  loading: loadingSlice,
  errors: errorsSlice,
  projects: projectsSlice,
  investors: investorsSlice,
  investments: investmentsSlice,
  distributions: distributionsSlice,
  interests: interestsSlice,
  partials: partialsSlice,
  loans: loansSlice,
  fees: feesSlice,
  reports: reportsSlice,
});

const store = configureStore({ reducer: rootReducer });

export default store;
