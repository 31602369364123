import { useState } from "react";
import { Outlet } from "react-router-dom";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { Menu } from "@mui/icons-material";
import AppDrawer from "../../components/AppDrawer";
import AppFooter from "../../components/AppFooter";

const Dashboard = () => {
  const [open, setOpen] = useState(false);

  const openDrawer = () => setOpen(true);

  const closeDrawer = () => setOpen(false);

  return (
    <>
      <AppBar position="static" sx={{ padding: "0.5rem" }}>
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            sx={{ mr: 1.5 }}
            onClick={openDrawer}
          >
            <Menu />
          </IconButton>
          <Typography variant="h5" color="inherit" component="div">
            IDE Admin
          </Typography>
        </Toolbar>
      </AppBar>
      <AppDrawer open={open} onClose={closeDrawer} />
      <Box
        component="main"
        sx={{ py: "2rem", px: { xs: "1rem", md: "1.5rem" } }}
      >
        <Outlet />
      </Box>
      <AppFooter />
    </>
  );
};

export default Dashboard;
