import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Check, Delete } from '@mui/icons-material';
import { TableRow, TableCell, IconButton, TextField } from '@mui/material';
import { useState } from 'react';
import CurrencyField from '../inputs/CurrencyField';

import { deleteCurrentPartial, updateCurrentPartial } from '../../actions/partialsActions';

const ProjectDetailsPartialItem = props => {
  const dispatch = useDispatch();

  const params = useParams();

  const [data, setData] = useState({
    concept: props.concept ?? '',
    total: props.total ?? 0,
    paid: props.paid ?? 0,
  });

  const handleChange = e => setData(prev => ({ ...prev, [e.target.name]: e.target.rawValue ?? e.target.value }));

  const handleSave = e => {
    e?.preventDefault();
    dispatch(updateCurrentPartial({ id: props._id, partial: data }));
  }

  const handleDelete = e => {
    e?.preventDefault();
    dispatch(deleteCurrentPartial({ id: props._id, query: { projectRef: params.id } }));
  }

  return (
    <TableRow>
      <TableCell><TextField label="Concepto" name="concept" value={data.concept} onChange={handleChange} /></TableCell>
      <TableCell><CurrencyField label="Total" name="total" value={data.total} onChange={handleChange} /></TableCell>
      <TableCell><CurrencyField label="Pagadas" name="paid" value={data.paid} onChange={handleChange} /></TableCell>
      <TableCell><CurrencyField disabled label="Por pagar" value={data.total - data.paid} /></TableCell>
      <TableCell><IconButton onClick={handleSave}><Check /></IconButton></TableCell>
      <TableCell><IconButton onClick={handleDelete}><Delete /></IconButton></TableCell>
    </TableRow>
  );
}

export default ProjectDetailsPartialItem;
