const { REACT_APP_API_BASE_URL } = process.env;

export const getFees = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    ...query
  }).toString();
  const request = new Request(REACT_APP_API_BASE_URL + '/fees?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getFee = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/fees/' + id, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const createFee = (token, fee) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/fees', {
    method: 'POST',
    body: JSON.stringify({ fees: fee }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const updateFee = (token, id, fee) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/fees/' + id, {
    method: 'PUT',
    body: JSON.stringify({ fees: fee }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const deleteFee = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/fees/' + id, {
    method: 'DELETE',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});
