import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createDistribution,
  deleteDistribution,
  getDistribution,
  getDistributions,
  updateDistribution,
} from "../api/distributionsApi";
import { showAlert } from "../slices/alertsSlice";
import {
  setCurrentDistribution,
  setDistributionsList,
} from "../slices/distributionsSlice";
import { handleError } from "../slices/errorsSlice";
import { startLoading, endLoading } from "../slices/loadingSlice";

export const getDistributionsList = createAsyncThunk(
  "distributions/getDistributionsList",
  ({ query }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getDistributions(token, query)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) =>
        dispatch(
          setDistributionsList({ distributions: data.data.distributions })
        )
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const getCurrentDistribution = createAsyncThunk(
  "distributions/getCurrentDistribution",
  ({ id }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getDistribution(token, id)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) =>
        dispatch(setCurrentDistribution({ distribution: data.distribution }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const createNewDistribution = createAsyncThunk(
  "distributions/createNewDistribution",
  ({ projectId, distribution, query, callback }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    createDistribution(token, projectId, distribution)
      .then((response) =>
        response.status === 201 ? response.json() : Promise.reject(response)
      )
      .then((data) => callback(data.distribution))
      .then(() => dispatch(getDistributionsList({ query })))
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const updateCurrentDistribution = createAsyncThunk(
  "distributions/updateCurrentDistribution",
  ({ id, distribution, callback = () => {} }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    updateDistribution(token, id, distribution)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then(() =>
        dispatch(
          showAlert({ message: "Distribución actualizado", type: "success" })
        )
      )
      .then(() => {
        callback();
      })
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const deleteCurrentDistribution = createAsyncThunk(
  "distributions/deleteCurrentDistribution",
  ({ id, query }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    deleteDistribution(token, id)
      .then((response) =>
        response.status === 200 ? true : Promise.reject(response)
      )
      .then(() => dispatch(getDistributionsList({ query })))
      .then(() =>
        dispatch(
          showAlert({ message: "Distribución borrada", type: "success" })
        )
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);
