import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetInvestmentsState } from "../../../slices/investmentsSlice";
import { Box, Card, Typography } from "@mui/material";
import AppPagination from "../../../components/AppPagination";
import { getProjectsListWithAssociateList } from "../../../actions/projectsActions";
import { getAssociatesList } from "../../../actions/associatesActions";
import AssociatesFilter from "./AssociateFilter";
import AssociateListTable from "./AssociateListTable";

const defaultFilter = {
  name: "",
};

function AssociatePage() {
  const dispatch = useDispatch();
  // const investors = useSelector((state) => state.investments.list);

  const projects = useSelector((state) => state.projects.list);
  const associates = useSelector((state) => state.associates.list);

  const [filter, setFilter] = useState({ ...defaultFilter });
  const [pagination, setPagination] = useState({ page: 1 });

  useEffect(() => {
    dispatch(
      getProjectsListWithAssociateList({
        query: {
          pagination: pagination.page,
          results: 25,
        },
      })
    );
    setPagination({ page: 1 });
    return () => {
      dispatch(resetInvestmentsState());
    };
  }, []);

  useEffect(() => {
    const f = { page: pagination.page };
    filter.name.length > 0 ? (f.name = filter.name) : null;
    dispatch(getAssociatesList({ query: f }));
  }, [pagination]);

  const handleChange = (e) =>
    setFilter({ ...filter, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    setPagination({ page: 1 });
  };

  const onClear = () => {
    setFilter({ ...defaultFilter });
    setPagination({ page: 1 });
  };

  const nextPage = () => setPagination({ page: pagination.page + 1 });
  const prevPage = () =>
    setPagination({ page: Math.max(pagination.page - 1, 1) });

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ marginBottom: "1rem" }}
        >
          Inversionistas
        </Typography>
      </Box>
      <AssociatesFilter
        {...filter}
        onChange={handleChange}
        onSubmit={onSubmit}
        onClear={onClear}
      />
      <AssociateListTable
        investors={associates}
        projects={projects}
        onDelete={onClear}
      />
      {(associates?.length ?? 0) === 0 && (
        <Card elevation={4} sx={{ my: 2, p: 2, width: "100%" }}>
          <Typography>No hay asociados</Typography>
        </Card>
      )}
      <AppPagination page={pagination.page} prev={prevPage} next={nextPage} />
    </>
  );
}

export default AssociatePage;
