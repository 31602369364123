import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import InvestorProjectDetailsInfo from "./InvestorProjectDetailsInfo";
import InvestorProjectDetailsInvesment from "./InvestorProjectDetailsInvestment";
import InvestorProjectDetailsInterests from "./InvestorProjectDetailsInterests";

import {
  getCurrentInvestment,
  updateCurrentInvestment,
} from "../../../../../actions/investmentsActions";
import { resetInvestmentsState } from "../../../../../slices/investmentsSlice";
import { getDistributionsList } from "../../../../../actions/distributionsActions";
import { getInterestsList } from "../../../../../actions/interestsActions";
import { getReportsList } from "../../../../../actions/reportsActions";
import { getCurrentInvestor } from "../../../../../actions/investorsActions";
import { resetInvestorsState } from "../../../../../slices/investorsSlice";
import NewInvestorProjectDetailDistributions from "./InvestorProjectDetailDistributions/InvestorProjectDetailDistributions";

const styles = {
  columns: {
    px: "1rem",
    mb: "2rem",
  },
};

const InvestorProjectDetails = () => {
  const params = useParams();
  let { investmentId } = useParams();

  const dispatch = useDispatch();
  const current = useSelector((state) => state.investments.current);
  const investor = useSelector((state) => state.investors.current);

  const [investments, setInvestments] = useState([]);

  useEffect(() => {
    dispatch(getCurrentInvestor({ id: params.id }));
    dispatch(getCurrentInvestment({ id: params.investmentId }));
    return () => {
      dispatch(resetInvestmentsState());
      dispatch(resetInvestorsState());
    };
  }, []);

  useEffect(() => {
    if (
      current &&
      current[0]?.associateRef?._id &&
      current[0]?.projectRef?._id
    ) {
      const associateRef = current[0]?.associateRef._id;
      const projectRef = current[0]?.projectRef._id;
      dispatch(
        getDistributionsList({
          query: {
            associateRef,
            projectRef,
            associateInvestmentId: investmentId,
          },
        })
      );
      dispatch(
        getInterestsList({
          query: {
            associateRef,
            projectRef,
            associateInvestmentId: investmentId,
          },
        })
      );
      dispatch(
        getReportsList({
          query: {
            associateRef,
            projectRef,
            associateInvestmentId: investmentId,
          },
        })
      );
    }
  }, [current]);

  useEffect(() => setInvestments(current), [current]);

  const handleSubmit = (e) => {
    e?.preventDefault();
    dispatch(
      updateCurrentInvestment({
        id: investmentId,
        investment: investments.find((i) => i._id === investmentId),
      })
    );
  };

  const handleChange = (e, investmentIndex) => {
    const investmentsValues = Object.values(investments);
    const investmentToEdit = investmentsValues.find(
      (i) => i._id === investmentId
    );

    let { ...newInvestment } = investmentToEdit;

    newInvestment[e.target.name] = e.target.rawValue ?? e.target.value;

    const copy = investments.map((inve) => {
      if (inve._id === investmentId) {
        if (e.target.name === "credit") {
          return {
            ...newInvestment,
            credit: { credit: e.target.rawValue ?? e.target.value },
          };
        }
        return newInvestment;
      }
      return inve;
    });
    setInvestments(copy);
  };

  return (
    <>
      <Typography variant="h4" fontWeight="bold" sx={{ marginBottom: "1rem" }}>
        Inversionista {investor.name ?? params.id}
      </Typography>
      <Grid container>
        <Grid item xs={12} md={6} sx={styles.columns}>
          {investments && <InvestorProjectDetailsInfo {...investments[0]} />}
        </Grid>
        <Grid item xs={12} md={6} sx={styles.columns}>
          <InvestorProjectDetailsInvesment
            investments={investments}
            onChange={handleChange}
            onSubmit={handleSubmit}
          />
        </Grid>
        <Grid item xs={12} sx={styles.columns}>
          <InvestorProjectDetailsInterests />
        </Grid>
        <Grid item xs={12} sx={styles.columns}>
          <NewInvestorProjectDetailDistributions />
        </Grid>
      </Grid>
    </>
  );
};

export default InvestorProjectDetails;
