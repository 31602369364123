import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import AssociateListItem from "./AssociateListItem";

const AssociateListTable = (props) => {
  const { projects, investors } = props;
  return (
    <TableContainer component={Paper} sx={{ maxHeight: "65vh" }}>
      <Table stickyHeader>
        <TableHead color="primary">
          <TableRow>
            <TableCell style={{ backgroundColor: "#081D2C", width: "20px" }}>
              <Typography
                textAlign={"center"}
                variant="caption"
                component={"p"}
                fontWeight={"bold"}
              >
                #
              </Typography>
            </TableCell>
            <TableCell style={{ backgroundColor: "#081D2C", width: "180px" }}>
              Nombre
            </TableCell>

            {projects?.map((project) => (
              <TableCell
                sx={{ width: "20px", backgroundColor: "#081D2C" }}
                key={project._id}
              >
                <Typography
                  textAlign={"center"}
                  variant="caption"
                  component={"p"}
                  fontWeight={"bold"}
                >
                  {project.alias}
                </Typography>
              </TableCell>
            ))}
            <TableCell style={{ backgroundColor: "#081D2C", width: "50px" }}>
              <Typography
                textAlign={"center"}
                variant="caption"
                component={"p"}
                fontWeight={"bold"}
              >
                ID
              </Typography>
            </TableCell>
            {/* <TableCell>Proyecto</TableCell>
            <TableCell>Fecha de inversión</TableCell>
            <TableCell>Inversión en el proyecto</TableCell> */}
            {/* <TableCell style={{ backgroundColor: "#081D2C" }}>Borrar</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {investors?.map((associate, index) => (
            <AssociateListItem
              key={associate._id}
              {...associate}
              index={index}
              projects={projects}
              onDelete={props.onDelete}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AssociateListTable;
