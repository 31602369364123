import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  IconButton,
  Divider,
  Grid,
  TextField,
  Autocomplete,
} from "@mui/material";

import CurrencyField from "../../../../components/inputs/CurrencyField";
import PercentageField from "../../../../components/inputs/PercentageField";

import {
  createNewInvestor,
  getInvestorsList,
} from "../../../../actions/investorsActions";
import { createNewInvestment } from "../../../../actions/investmentsActions";

const styles = {
  container: {
    display: "block",
  },
  content: {
    paddingY: 0,
  },
  actions: {
    paddingRight: "1rem",
  },
  title: {
    my: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fieldWrapper: {
    px: "1rem",
  },
  column: {
    px: "1rem",
  },
};

const defaultInvestment = {
  vehiculo: "",
  capital: 0,
  participation: 0,
  investmentDate: new Date().toISOString().slice(0, 10),
};

const defaultInvestor = {
  name: "",
  investor: "",
  user: "",
  password: "",
};

const ProjectDetailsInvestorAdd = (props) => {
  const [selected, setSelected] = useState();
  const [investment, setInvestment] = useState({ ...defaultInvestment });

  const [investor, setInvestor] = useState({ ...defaultInvestor });

  const investors = useSelector((state) => state.investors.list);

  useEffect(() => {
    dispatch(getInvestorsList({ query: { results: 9999 } }));
  }, []);

  const dispatch = useDispatch();
  const params = useParams();

  const handleChange = (e) =>
    setInvestment({
      ...investment,
      [e.target.name]: e.target.rawValue ?? e.target.value,
    });

  const handleChangeInvestor = (e) =>
    setInvestor({
      ...investor,
      [e.target.name]: e.target.rawValue ?? e.target.value,
    });

  const handleClose = (e) => {
    e?.preventDefault();
    props.onClose();
    setInvestor({ ...defaultInvestor });

    setInvestment({ ...defaultInvestment });
  };

  const handleAdd = (e) => {
    e?.preventDefault();
    dispatch(
      createNewInvestment({
        id: selected,
        investment: { ...investment, projectRef: params.id },
        query: { projectRef: params.id, results: 999999 },
        callback: handleClose,
      })
    );
  };

  const handleCreate = (e) => {
    e?.preventDefault();
    dispatch(
      createNewInvestor({
        investor,
        callback: (id) => {
          dispatch(
            createNewInvestment({
              id,
              investment: { ...investment, projectRef: params.id },
              query: { projectRef: params.id, results: 999999 },
              callback: handleClose,
            })
          );
        },
      })
    );
  };

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>
        <Box sx={styles.title}>
          <Typography variant="h5">Agregar inversionista</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container>
          <Grid container direction="row" justifyContent="center">
            <Grid item xs={6} sx={styles.column}>
              <Typography variant="h6">Datos de inversión</Typography>
              <TextField
                label="Vehículo"
                name="vehiculo"
                value={investment.vehiculo}
                onChange={handleChange}
              />
              <CurrencyField
                label="Capital"
                name="capital"
                value={investment.capital}
                onChange={handleChange}
              />
              <PercentageField
                label="Participación"
                name="participation"
                value={investment.participation}
                onChange={handleChange}
              />
              <TextField
                type="date"
                label="Fecha de inversión"
                name="investmentDate"
                value={investment.investmentDate}
                onChange={handleChange}
              />
            </Grid>
            {/* <Grid item xs={6} sx={styles.column}>
              <Typography variant="h6">Datos de inversión</Typography>
              <TextField label="Vehículo" name="vehiculo1" value={investment.vehiculo} onChange={handleChange} />
              <CurrencyField label="Capital" name="capital1" value={investment.capital} onChange={handleChange} />
              <PercentageField label="Participación" name="participation1" value={investment.participation} onChange={handleChange} />
              <TextField type="date" label="Fecha de inversión" name="investmentDate1" value={investment.investmentDate} onChange={handleChange} />
            </Grid> */}
          </Grid>

          {/* <Button>Agregar Vehiculo</Button> */}
          <Grid item xs={12} md={6} sx={styles.column}>
            <Typography variant="h6">Nuevo inversionista</Typography>
            <TextField
              label="Nombre"
              name="investor"
              value={investor.investor}
              onChange={handleChangeInvestor}
            />
            <TextField
              label="Contacto"
              name="name"
              value={investor.name}
              onChange={handleChangeInvestor}
            />
            <TextField
              label="Nombre de usuario"
              name="user"
              value={investor.user}
              onChange={handleChangeInvestor}
            />
            <TextField
              label="Contraseña"
              name="password"
              value={investor.password}
              onChange={handleChangeInvestor}
            />
            <Box sx={{ textAlign: "end" }}>
              <Button onClick={handleCreate}>Crear y añadir</Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={styles.column}>
            <Typography variant="h6">Seleccionar inversionista</Typography>
            <Autocomplete
              value={selected}
              onChange={(event, newValue) => {
                setSelected(newValue?._id); // Assuming you have a `setSelected` function from useState
              }}
              options={investors.filter(
                (i) => !i?.name?.toLowerCase().includes("prueba")
              )}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Inversionista" name="selected" />
              )}
            />
            <Box sx={{ textAlign: "end" }}>
              <Button onClick={handleAdd}>Añadir</Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ProjectDetailsInvestorAdd;
