import { createAsyncThunk } from '@reduxjs/toolkit';
import { createPartial, deletePartial, getPartial, getPartials, updatePartial } from '../api/partialsApi';
import { showAlert } from '../slices/alertsSlice';
import { setCurrentPartial, setPartialsList } from '../slices/partialsSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';

export const getPartialsList = createAsyncThunk('partials/getPartialsList', ({ query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getPartials(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setPartialsList({ partials: data.parcialities })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const getCurrentPartial = createAsyncThunk('partials/getCurrentPartial', ({ id }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getPartial(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentPartial({ partial: data.parciality })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const createNewPartial = createAsyncThunk('partials/createNewPartial', ({ partial, callback }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createPartial(token, partial)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.partial))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const updateCurrentPartial = createAsyncThunk('partials/updateCurrentPartial', ({ id, partial }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  updatePartial(token, id, partial)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(() => dispatch(showAlert({ message: 'Aportación actualizada', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const deleteCurrentPartial = createAsyncThunk('partials/deleteCurrentPartial', ({ id, query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  deletePartial(token, id)
  .then(response => response.status === 200 ? true : Promise.reject(response))
  .then(() => dispatch(getPartialsList({ query })))
  .then(() => dispatch(showAlert({ message: 'Aportación borrada', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});
