import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  current: {}
}

const investorsSlice = createSlice({
  name: 'investors',
  initialState,
  reducers: {
    setInvestorsList: (state, { payload }) => ({ ...state, list: [...payload.investors] }),
    setCurrentInvestor: (state, { payload }) => ({ ...state, current: { ...payload.investor } }),
    resetInvestorsState: () => ({ list: [], current: { } })
  }
});

export const { setInvestorsList, setCurrentInvestor, resetInvestorsState } = investorsSlice.actions;
export default investorsSlice.reducer;
