import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, Button, DialogActions, Table, TableHead, TableRow, TableCell, TableBody, Typography, IconButton, Divider } from '@mui/material';
import ProjectDetailsLoanCreate from './ProjectDetailsLoanCreate';
import ProjectDetailsLoanItem from '../../../../components/items/ProjectDetailsLoanItem';

const styles = {
  container: {
    display: 'block',
  },
  content: {
    paddingY: 0,
  },
  actions: {
    paddingRight: '1rem'
  },
  title: {
    my: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fieldWrapper: {
    px: '1rem'
  }
}

const ProjectDetailsLoans = props => {
  const loans = useSelector(state => state.loans.list);
  const [openCreate, setOpenCreate] = useState(false);

  const handleClose = e => {
    e?.preventDefault();
    props.onClose();
  }

  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);

  return (
    <>
      <Dialog
        open={props.open}
        onClose={handleClose}
      >
        <DialogTitle>
        <Box sx={styles.title}>
            <Typography variant="h5">Créditos</Typography>
            <IconButton onClick={handleClose}><Close /></IconButton>
          </Box>
          <Divider />
        </DialogTitle>
        <DialogContent sx={styles.content}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Monto</TableCell>
                <TableCell>Plazo</TableCell>
                <TableCell>Tasa</TableCell>
                <TableCell>Amortización</TableCell>
                <TableCell>Periodo de gracia</TableCell>
                <TableCell>Fecha de disposición</TableCell>
                <TableCell>Guardar</TableCell>
                <TableCell>Borrar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loans.map(loan => <ProjectDetailsLoanItem key={loan._id} {...loan} />)}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions sx={styles.actions}>
          <Button onClick={handleOpenCreate}>Añadir</Button>
        </DialogActions>
      </Dialog>
      <ProjectDetailsLoanCreate open={openCreate} onClose={handleCloseCreate} />
    </>
  );
}

export default ProjectDetailsLoans;
