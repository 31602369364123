import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  Typography,
  IconButton,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import CurrencyField from "../../../../../components/inputs/CurrencyField";

import { createNewInvestorInterest } from "../../../../../actions/investorsActions";
import { useParams } from "react-router-dom";

const styles = {
  container: {
    display: "block",
  },
  content: {
    paddingY: 0,
  },
  actions: {
    paddingRight: "1rem",
  },
  title: {
    my: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fieldWrapper: {
    px: "1rem",
  },
};

const InvestorProjectDetailsInterestCreate = (props) => {
  let { investmentId } = useParams();
  const current = useSelector((state) => state.investments.current);

  const [interest, setInterest] = useState({
    date: new Date().toISOString().slice(0, 10),
    period: "",
    amount: "",
    fees: "",
    netDistribution: "",
    mxn: "0",
    change: "0",
    accumulatedDistribution: "",
    accumulatedFess: "",
    retention: "",
    netAccumulated: "",
  });

  const dispatch = useDispatch();

  const handleChange = (e) =>
    setInterest({
      ...interest,
      [e.target.name]: e.target.rawValue ?? e.target.value,
    });

  const handleClose = (e) => {
    e?.preventDefault();
    props.onClose();
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    dispatch(
      createNewInvestorInterest({
        id: current[0].associateRef._id,
        interest: {
          ...interest,
          projectRef: current[0].projectRef._id,
          investmentRef: investmentId,
        },
        query: {
          projectRef: current[0].projectRef._id,
          associateRef: current[0].associateRef._id,
          associateInvestmentId: investmentId,
        },
        callback: handleClose,
      })
    );
  };

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>
        <Box sx={styles.title}>
          <Typography variant="h5">Crear interés</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              type="date"
              label="Fecha"
              name="date"
              value={interest.date}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Periodo"
              name="period"
              value={interest.period}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <CurrencyField
              label="Monto"
              name="amount"
              value={interest.amount}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <CurrencyField
              label="Fees"
              name="fees"
              value={interest.fees}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <CurrencyField
              label="Distribución neta"
              name="netDistribution"
              value={interest.netDistribution}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <CurrencyField
              label="Distribución acumulada"
              name="accumulatedDistribution"
              value={interest.accumulatedDistribution}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <CurrencyField
              label="Fees acumulados"
              name="accumulatedFess"
              value={interest.accumulatedFess}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <CurrencyField
              label="Retenciones acumuladas"
              name="retention"
              value={interest.retention}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <CurrencyField
              label="Neto acumulado"
              name="netAccumulated"
              value={interest.netAccumulated}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button onClick={handleSubmit}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvestorProjectDetailsInterestCreate;
